import React, { Fragment, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import {
  Close,
  FormatListBulleted,
  SavedSearch,
  QueryStats,
  QuestionMark,
  Upload,
} from '@mui/icons-material';
import queryString from 'query-string';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { cleanupResults } from 'generic/core/search/actions';
import { openDialogUploadFiles } from 'generic/core/actions/actions';
import SearchContainer from 'generic/containers/SearchContainer';
import HeaderMenuItem from 'generic/components/ui/HeaderMenuItem';
import UploadFilesDialogContainer from 'generic/containers/UploadFilesDialogContainer';

const HeaderSearchWrapper = ({ showSearchInput, headerBackgroundColorIsDark }) => {
  const RAGFieldId = useSelector((state) => state.config.activeBase.idChampRAG);
  const baseId = useSelector((state) => state.config.activeBase.base);
  const location = useLocation();
  const locationParams = queryString.parse(_.get(location, 'search', ''));
  const [isRAGOpened, setIsRAGOpened] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [showRAG, setShowRAG] = useState(false);
  const { t } = useTranslation();
  const { replace } = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const { UPLOAD_FILE_SETTINGS } = QES_CONSTANTS;

  const handleToggleRAG = () => {
    setIsRAGOpened(!isRAGOpened);
    if (Object.keys(locationParams).includes(`F_${RAGFieldId}`)) {
      replace('/search/results');
      dispatch(cleanupResults());
    }
  };

  useEffect(() => {
    if (RAGFieldId && Object.keys(locationParams).includes(`F_${RAGFieldId}`)) {
      setIsRAGOpened(true);
      setShowSearch(false);
      setShowRAG(true);
    }
  }, [locationParams, RAGFieldId]);

  useEffect(() => {
    if (isRAGOpened) {
      setShowRAG(true);
      const timer = setTimeout(() => {
        setShowSearch(false);
      }, 800);
      return () => {
        clearTimeout(timer);
      };
    }
    setShowSearch(true);
    const timer = setTimeout(() => {
      setShowRAG(false);
    }, 800);
    return () => {
      clearTimeout(timer);
    };
  }, [isRAGOpened]);

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      columnGap="5px"
      flexGrow="1"
      maxWidth="680px"
      mx={2}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: '1',
          opacity: !isRAGOpened ? 1 : 0,
          transform: !isRAGOpened ? 'initial' : 'scaleX(0)',
          transformOrigin: 'center left',
          transition: 'transform 0.8s, opacity 0.8s',
        }}
      >
        {showSearch && (
          <Box
            display="flex"
            flexGrow="1"
            columnGap="5px"
          >
            <Box
              display={showSearchInput ? 'block' : 'none'}
              flexGrow="1"
            >
              <SearchContainer
                version="advanced"
                isVisible={showSearchInput}
              />
            </Box>
            {!smallerThanLarge && (
              <Fragment>
                <Tooltip
                  title={t('header.dashboard')}
                >
                  <HeaderMenuItem
                    component={NavLink}
                    to={`/search/dashboard/${baseId}${location?.search || ''}`}
                    selected={location.pathname.startsWith('/search/dashboard')}
                    sx={{
                      textAlign: 'center',
                      p: '6px',
                      ml: 0.5,
                    }}
                  >
                    <QueryStats />
                  </HeaderMenuItem>
                </Tooltip>
                <Divider orientation="vertical" color={headerBackgroundColorIsDark ? '#fff' : '#000'} flexItem />
                <Tooltip
                  title={t('header.results')}
                >
                  <HeaderMenuItem
                    component={NavLink}
                    to={`/search/results/${baseId}${location?.search || ''}`}
                    selected={location.pathname.startsWith('/search/results')}
                    sx={{
                      textAlign: 'center',
                      p: '6px',
                    }}
                  >
                    <FormatListBulleted />
                  </HeaderMenuItem>
                </Tooltip>
              </Fragment>
            )}
          </Box>
        )}
      </Box>

      {RAGFieldId && (
        <Fragment>
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              width: '95%',
              opacity: isRAGOpened ? 1 : 0,
              transform: isRAGOpened ? 'scaleX(1)' : 'scaleX(0)',
              transformOrigin: 'center right',
              transition: ' transform 0.8s, opacity 0.8s',
            }}
          >
            {showRAG && (
              <Box
                display={showSearchInput ? 'block' : 'none'}
              >
                <SearchContainer
                  version="simple"
                  uniqueFieldCode={`F_${RAGFieldId}`}
                  uniqueFieldPlaceholder={t('form.ask')}
                  uniqueFieldIcon={<QuestionMark />}
                  fullSpinnerOnSubmit
                  goToResultsPageOnSubmit
                />
              </Box>
            )}
          </Box>
          <Tooltip title={isRAGOpened ? t('header.switch_to_search') : t('header.switch_to_question')}>
            <IconButton
              onClick={handleToggleRAG}
              sx={{
                display: showSearchInput ? 'block' : 'none',
                ml: 1,
                height: '40px',
                color: headerBackgroundColorIsDark ? 'common.white' : 'common.black',
                borderRadius: '20px',
                backgroundColor: headerBackgroundColorIsDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.1)',
                zIndex: 2,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: headerBackgroundColorIsDark ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.2)',
                },
              }}
            >
              {isRAGOpened ? <Close /> : <SavedSearch />}
            </IconButton>
          </Tooltip>
        </Fragment>
      )}

      { UPLOAD_FILE_SETTINGS.ENABLE && (
        <Fragment>
          <Tooltip title={t('header.upload_documents')}>
            <IconButton
              onClick={() => dispatch(openDialogUploadFiles())}
              sx={{
                ml: 1,
                height: '40px',
                color: headerBackgroundColorIsDark ? 'common.white' : 'common.black',
                borderRadius: '20px',
                backgroundColor: headerBackgroundColorIsDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.1)',
                zIndex: 2,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: headerBackgroundColorIsDark ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.2)',
                },
              }}
            >
              <Upload />
            </IconButton>
          </Tooltip>
          <UploadFilesDialogContainer
            baseId={UPLOAD_FILE_SETTINGS.BASE}
            baseMisePageId={UPLOAD_FILE_SETTINGS.BASE_MISE_PAGE}
          />
        </Fragment>
      )}
    </Box>
  );
};

HeaderSearchWrapper.propTypes = {
  showSearchInput: PropTypes.bool,
  headerBackgroundColorIsDark: PropTypes.bool,
};

HeaderSearchWrapper.defaultProps = {
  showSearchInput: true,
  headerBackgroundColorIsDark: true,
};

export default HeaderSearchWrapper;

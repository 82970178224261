import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AddShoppingCart,
  ArrowDropDown,
  AutoFixNormal,
  CheckCircle,
  CheckCircleOutline,
  Clear,
  DeleteForever,
  DeleteOutline,
  Edit,
  ErrorOutline,
  FileDownloadOutlined,
  ForwardToInbox,
  LibraryAddCheck,
  LibraryAddCheckOutlined,
  MailOutlined,
  Summarize,
  Unpublished,
} from '@mui/icons-material';

import QES_CONSTANTS from 'generic/core/qes/constants';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { actionsPropType } from 'generic/core/qes/proptypes';
import ResponsiveButtonGroupToMenu from 'generic/components/ui/ResponsiveButtonGroupToMenu';

const StyledTooltipButton = ({ children, ...rest }) => (
  <TooltipButton
    color="secondary"
    size="small"
    {...rest}
  >
    {children}
  </TooltipButton>
);

StyledTooltipButton.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
StyledTooltipButton.defaultProps = {
  disabled: false,
};

const ResultsActions = ({
  canAddToCart,
  canAddToNewsletter,
  canEditInGED,
  canDeleteDocument,
  canSendToFriend,
  canExport,
  canSummarize,
  handleClearSelection,
  handleOpenAddToCart,
  handleOpenAddToNewsletter,
  handleOpenMultipleGed,
  handleOpenSelection,
  handleOpenSendToFriend,
  handleOpenDeleteArticleDialog,
  handleOpenExport,
  handleOpenSummarize,
  actions,
  handleOpenUpdateStatusArticleDialog,
  isSelectionActions,
  loading,
  nbCheckedItems,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const btnProps = {
    color: 'secondary',
    size: 'small',
  };

  const { ETAT_DOCUMENT } = QES_CONSTANTS;

  const computeIconStatus = (status) => {
    switch (status) {
      case ETAT_DOCUMENT.NOUVEAU:
        return <Unpublished />;
      case ETAT_DOCUMENT.REJETE:
        return <DeleteOutline />;
      case ETAT_DOCUMENT.COLLECTE:
        return <LibraryAddCheck />;
      case ETAT_DOCUMENT.ERREUR:
        return <ErrorOutline />;
      case ETAT_DOCUMENT.VALIDE:
        return <CheckCircleOutline />;
      case ETAT_DOCUMENT.PUBLIE:
        return <CheckCircle />;
      default:
        return <AutoFixNormal />;
    }
  };

  const globalDisabled = loading || nbCheckedItems === 0;
  const actionsItems = [];
  let selectionActionItem;
  if (isSelectionActions) {
    let clearSelectionTitle = t('selection.clear');
    let ClearIcon = Clear;
    if (smallerThanLarge) {
      clearSelectionTitle = `${t('selection.clear')} (${nbCheckedItems})`;
    } else {
      // eslint-disable-next-line react/no-unstable-nested-components
      ClearIcon = () => (
        <Fragment>
          <Clear />
          &nbsp;
          {nbCheckedItems}
        </Fragment>
      );
    }
    selectionActionItem = {
      ...btnProps,
      icon: ClearIcon,
      onClick: handleClearSelection,
      title: clearSelectionTitle,
    };
  } else {
    let showSelectionTitle = t('selection.show');
    let ShowIcon = LibraryAddCheckOutlined;
    if (smallerThanLarge) {
      showSelectionTitle = `${t('selection.show')} (${nbCheckedItems})`;
    } else {
      // eslint-disable-next-line react/no-unstable-nested-components
      ShowIcon = () => (
        <Fragment>
          <LibraryAddCheckOutlined />
          &nbsp;
          {nbCheckedItems}
        </Fragment>
      );
    }
    selectionActionItem = {
      ...btnProps,
      disabled: nbCheckedItems === 0,
      icon: ShowIcon,
      onClick: handleOpenSelection,
      title: showSelectionTitle,
    };
  }
  actionsItems.push(selectionActionItem);
  if (canAddToCart) {
    actionsItems.push({
      ...btnProps,
      disabled: globalDisabled,
      icon: AddShoppingCart,
      onClick: handleOpenAddToCart,
      title: t('actions.add_to_cart'),
    });
  }
  if (canAddToNewsletter) {
    actionsItems.push({
      ...btnProps,
      disabled: globalDisabled,
      icon: ForwardToInbox,
      onClick: handleOpenAddToNewsletter,
      title: t('actions.add_to_newsletter'),
    });
  }
  if (canSendToFriend) {
    actionsItems.push({
      ...btnProps,
      disabled: globalDisabled,
      icon: MailOutlined,
      onClick: handleOpenSendToFriend,
      title: t('actions.send_to_friend'),
    });
  }
  if (canExport) {
    actionsItems.push({
      ...btnProps,
      disabled: globalDisabled,
      icon: FileDownloadOutlined,
      onClick: handleOpenExport,
      title: t('actions.export'),
    });
  }
  if (canSummarize) {
    actionsItems.push({
      ...btnProps,
      disabled: globalDisabled,
      icon: Summarize,
      onClick: handleOpenSummarize,
      title: t('actions.summarize'),
    });
  }

  const gedItems = [];
  if (canEditInGED) {
    gedItems.push({
      ...btnProps,
      disabled: globalDisabled,
      icon: Edit,
      onClick: handleOpenMultipleGed,
      title: t('actions.edit'),
    });
    _.each(
      _.reject(actions, { etat: ETAT_DOCUMENT.SUPPRIMER }),
      (action, key) => {
        let title = t(
          'ged.update_status_to',
          { count: nbCheckedItems, status: t(`ged.document.status.${action.etat}`) },
        );
        if (smallerThanLarge) {
          title = t(
            'ged.update_all_status_to',
            { status: t(`ged.document.status.${action.etat}`) },
          );
        }
        gedItems.push({
          ...btnProps,
          disabled: globalDisabled,
          icon: () => computeIconStatus(action.etat),
          key,
          onClick: () => handleOpenUpdateStatusArticleDialog(action.etat),
          title,
        });
      },
    );
  }
  if (canDeleteDocument) {
    gedItems.push({
      ...btnProps,
      color: 'warning',
      sx: { borderColor: 'primary' },
      disabled: globalDisabled,
      icon: DeleteForever,
      onClick: handleOpenDeleteArticleDialog,
      title: t('actions.delete'),
    });
  }
  return (
    <React.Fragment>
      <ResponsiveButtonGroupToMenu
        dropdownBtnChildren={t('actions.actions')}
        dropdownBtnProps={{
          color: 'secondary',
          endIcon: <ArrowDropDown />,
        }}
        dropdownBtnTag="button"
        items={actionsItems}
        shrink={smallerThanLarge}
      />

      {(canEditInGED || canDeleteDocument) && (
        <ResponsiveButtonGroupToMenu
          dropdownBtnChildren={t('actions.edition')}
          dropdownBtnProps={{
            color: 'secondary',
            endIcon: <ArrowDropDown />,
            variant: 'outlined',
            disabled: globalDisabled,
          }}
          dropdownBtnTag="button"
          items={gedItems}
          shrink={smallerThanLarge}
          variant="outlined"
        />
      )}
    </React.Fragment>
  );
};

ResultsActions.propTypes = {
  canAddToCart: PropTypes.bool.isRequired,
  canAddToNewsletter: PropTypes.bool.isRequired,
  canEditInGED: PropTypes.bool.isRequired,
  canDeleteDocument: PropTypes.bool.isRequired,
  canSendToFriend: PropTypes.bool.isRequired,
  canExport: PropTypes.bool.isRequired,
  canSummarize: PropTypes.bool.isRequired,
  isSelectionActions: PropTypes.bool,
  loading: PropTypes.bool,
  handleOpenAddToCart: PropTypes.func.isRequired,
  handleOpenAddToNewsletter: PropTypes.func.isRequired,
  handleOpenSendToFriend: PropTypes.func,
  handleOpenSelection: PropTypes.func,
  handleClearSelection: PropTypes.func,
  handleOpenMultipleGed: PropTypes.func,
  handleOpenDeleteArticleDialog: PropTypes.func,
  handleOpenExport: PropTypes.func,
  handleOpenSummarize: PropTypes.func,
  actions: PropTypes.arrayOf(actionsPropType),
  handleOpenUpdateStatusArticleDialog: PropTypes.func,
  nbCheckedItems: PropTypes.number.isRequired,
};
ResultsActions.defaultProps = {
  handleOpenSelection: null,
  handleClearSelection: null,
  handleOpenSendToFriend: null,
  handleOpenMultipleGed: null,
  handleOpenDeleteArticleDialog: null,
  handleOpenExport: null,
  handleOpenSummarize: null,
  actions: [],
  handleOpenUpdateStatusArticleDialog: null,
  isSelectionActions: false,
  loading: false,
};

export default ResultsActions;

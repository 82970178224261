import i18next from 'i18next';

export default {
  // Affichage du complet au centre des résultats pleine page
  COMPLETE_ON_RESULTS_FULL_PAGE: false,

  override_all: {
    DASHBOARDS_PARAMS_PER_BASE: {
      default: {
        WIDGETS: [
          {
            i: 'countryTopic',
            x: 0,
            y: 0,
            w: 3,
            h: 1,
            facets: 'QES_CountryCode.verbatim',
            aggregates: ['agg_QES_CountryCode.verbatim'],
            type: 'map',
            mindoccount: 1,
            facetmax: 10000,
            facetmax2: 10000,
            totalunique: true,
            title: i18next.t('dashboard.widget.countries'),
          },
          {
            i: 'locations',
            x: 3,
            y: 0,
            w: 3,
            h: 1,
            facets: 'QES_Location.verbatim',
            aggregates: ['agg_QES_Location.verbatim'],
            type: 'bar',
            totalunique: true,
            title: i18next.t('dashboard.widget.locations'),
          },
          {
            i: 'extensions',
            x: 0,
            y: 1,
            w: 2,
            h: 1,
            facets: 'PJ_Extension',
            aggregates: ['agg_PJ_Extension'],
            type: 'pie',
            totalunique: true,
            title: i18next.t('dashboard.widget.files_extensions'),
          },
          {
            i: 'langs',
            x: 2,
            y: 1,
            w: 2,
            h: 1,
            humanizeLangs: true,
            facets: 'PJ_Langue',
            aggregates: ['agg_PJ_Langue'],
            type: 'pie',
            totalunique: true,
            title: i18next.t('dashboard.widget.files_languages'),
          },
          {
            i: 'companies',
            x: 4,
            y: 1,
            w: 2,
            h: 1,
            facets: 'QES_Company.verbatim',
            aggregates: ['agg_QES_Company.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            totalunique: true,
            title: i18next.t('dashboard.widget.companies'),
          },
          {
            i: 'persons',
            x: 0,
            y: 2,
            w: 2,
            h: 1,
            facets: 'QES_Person.verbatim',
            aggregates: ['agg_QES_Person.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            totalunique: true,
            title: i18next.t('dashboard.widget.persons'),
          },
          {
            i: 'organizations',
            x: 2,
            y: 2,
            w: 2,
            h: 1,
            facets: 'QES_Organization.verbatim',
            aggregates: ['agg_QES_Organization.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            totalunique: true,
            title: i18next.t('dashboard.widget.organizations'),
          },
          {
            i: 'events',
            x: 4,
            y: 2,
            w: 2,
            h: 1,
            facets: 'QES_Event.verbatim',
            aggregates: ['agg_QES_Event.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            totalunique: true,
            title: i18next.t('dashboard.widget.events'),
          },
          {
            i: 'products',
            x: 0,
            y: 3,
            w: 2,
            h: 1,
            facets: 'QES_Product.verbatim',
            aggregates: ['agg_QES_Product.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            totalunique: true,
            title: i18next.t('dashboard.widget.products'),
          },
          {
            i: 'risks',
            x: 2,
            y: 3,
            w: 2,
            h: 1,
            facets: 'QES_ConceptRisk.verbatim',
            aggregates: ['agg_QES_ConceptRisk.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            totalunique: true,
            title: i18next.t('dashboard.widget.risks'),
          },
          {
            i: 'threat_actors',
            x: 4,
            y: 3,
            w: 2,
            h: 1,
            facets: 'QES_StixThreatActor.verbatim',
            aggregates: ['agg_QES_StixThreatActor.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            totalunique: true,
            title: i18next.t('dashboard.widget.threat_actors'),
          },
          {
            i: 'attack_patterns',
            x: 0,
            y: 4,
            w: 2,
            h: 1,
            facets: 'QES_StixAttackPattern.verbatim',
            aggregates: ['agg_QES_StixAttackPattern.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            totalunique: true,
            title: i18next.t('dashboard.widget.attack_patterns'),
          },
          {
            i: 'malwares',
            x: 2,
            y: 4,
            w: 2,
            h: 1,
            facets: 'QES_StixMalware.verbatim',
            aggregates: ['agg_QES_StixMalware.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            totalunique: true,
            title: i18next.t('dashboard.widget.malwares'),
          },
          {
            i: 'tools',
            x: 4,
            y: 4,
            w: 2,
            h: 1,
            facets: 'QES_StixTool.verbatim',
            aggregates: ['agg_QES_StixTool.verbatim'],
            facetmax: 50,
            type: 'wordcloud',
            totalunique: true,
            title: i18next.t('dashboard.widget.tools'),
          },
          {
            i: 'emails',
            x: 0,
            y: 5,
            w: 2,
            h: 1,
            facets: 'QES_Email',
            aggregates: ['agg_QES_Email'],
            facetmax: 50,
            type: 'wordcloud',
            totalunique: true,
            title: i18next.t('dashboard.widget.emails'),
          },
          {
            i: 'observed_data',
            x: 2,
            y: 5,
            w: 2,
            h: 1,
            facets: 'QES_StixObservedData.verbatim',
            aggregates: ['agg_QES_StixObservedData.verbatim'],
            facetmax: 500,
            type: 'datatable',
            totalunique: true,
            title: i18next.t('dashboard.widget.observed_data'),
          },
          {
            i: 'ipv4',
            x: 4,
            y: 5,
            w: 2,
            h: 1,
            facets: 'QES_Ipv4.verbatim',
            aggregates: ['agg_QES_Ipv4.verbatim'],
            facetmax: 500,
            type: 'datatable',
            totalunique: true,
            title: i18next.t('dashboard.widget.ipv4'),
          },
          {
            i: 'cooccurrences',
            x: 0,
            y: 6,
            w: 6,
            h: 2,
            facetmax: 200,
            facetmax2: 5,
            mindoccount: 1,
            type: 'networkgraph',
            title: i18next.t('dashboard.widget.cooccurrences_graph'),
          },
          {
            i: 'relations',
            x: 0,
            y: 8,
            w: 6,
            h: 2,
            facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
            facetmax: 200,
            facetmax2: 100,
            mindoccount: 1,
            type: 'networkgraph',
            title: i18next.t('dashboard.widget.relations_graph'),
          },
        ],

        COOCCURRENCES_FIELDS: [
          { value: 'QES_StixThreatActor.verbatim', name: 'Threat Actors', active: true },
          { value: 'QES_StixAttackPattern.verbatim', name: 'Attack Patterns', active: true },
          { value: 'QES_StixMalware.verbatim', name: 'Malwares', active: true },
          { value: 'QES_StixTool.verbatim', name: 'Tools', active: true },
          { value: 'QES_Ipv4.verbatim', name: 'Ipv4', active: true },
          { value: 'QES_Person.verbatim', name: 'Person' },
          { value: 'QES_Company.verbatim', name: 'Company' },
          { value: 'QES_Organization.verbatim', name: 'Organization' },
          { value: 'QES_ConceptRisk.verbatim', name: 'ConceptRisk' },
        ],

        RELATIONS_NODES_FIELDS: [
          { value: 'Organonoff', name: 'Organonoff', active: true },
          { value: 'Organization', name: 'Organization', active: true },
          { value: 'Product', name: 'Product', active: true },
          { value: 'Person', name: 'Person', active: true },
          { value: 'Concept', name: 'Concept', active: true },
          { value: 'Coldconcept', name: 'Coldconcept', active: true },
          { value: 'Location', name: 'Location', active: true },
          { value: 'Company', name: 'Company', active: true },
          { value: 'Phone', name: 'Phone', active: true },
          { value: 'Event', name: 'Event', active: true },
          { value: 'Unlocalized', name: 'Unlocalized', active: true },
          { value: 'Media', name: 'Media', active: true },
          { value: 'Email', name: 'Email', active: true },
          { value: 'Address', name: 'Address', active: true },
          { value: 'JobTitle', name: 'Job Title', active: true },
        ],

        RELATIONS_LINKS_FIELDS: [
          { name: 'Aim', value: 'Aim', active: true },
          { name: 'Alert', value: 'Alert', active: true },
          { name: 'Appoint', value: 'Appoint', active: true },
          { name: 'Attack', value: 'Attack', active: true },
          { name: 'Develop', value: 'Develop', active: true },
          { name: 'Help', value: 'Help', active: true },
          { name: 'Identification', value: 'Identification', active: true },
          { name: 'Include', value: 'Include', active: true },
          { name: 'Located', value: 'Located', active: true },
          { name: 'Participate', value: 'Participate', active: true },
          { name: 'Protect', value: 'Protect', active: true },
          { name: 'Provide', value: 'Provide', active: true },
          { name: 'Report', value: 'Report', active: true },
          { name: 'Test', value: 'Test', active: true },
          { name: 'Supply', value: 'Supply', active: true },
          { name: 'Support', value: 'Support', active: true },
          { name: 'Use', value: 'Use', active: true },
          { name: 'Job Title', value: 'JobTitle', active: true },
        ],
      },
    },
  },
  DYNAMIC_DASHBOARD: false,
};

import React, {
  Fragment,
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { formatDistance, parseISO } from 'date-fns';
import _ from 'lodash';
import {
  Box,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import { format } from 'generic/utils/dateUtils';
import {
  CheckCircleOutline, ErrorOutline, InfoOutlined, WarningOutlined,
} from '@mui/icons-material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { scheduleRapportPropType, scheduleTraitementPropType } from 'generic/core/qes/proptypes';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';
import CardWithStatus from './CardWithStatus';

const { API_ENTRY_POINT } = QES_CONSTANTS;

const STATUSES = {
  1: {
    severity: 'success',
    icon: <CheckCircleOutline sx={{ color: 'success.light' }} />,
    message: i18next.t('schedules.tabs.reports.statuses.1'),
  },
  2: {
    severity: 'info',
    icon: <InfoOutlined sx={{ color: 'info.light' }} />,
    message: i18next.t('schedules.tabs.reports.statuses.2'),
  },
  3: {
    severity: 'error',
    icon: <ErrorOutline sx={{ color: 'error.light' }} />,
    message: i18next.t('schedules.tabs.reports.statuses.3'),
  },
  4: {
    severity: 'error',
    icon: <ErrorOutline sx={{ color: 'error.light' }} />,
    message: i18next.t('schedules.tabs.reports.statuses.4'),
  },
  1000: {
    severity: 'warning',
    icon: <WarningOutlined sx={{ color: 'warning.light' }} />,
    message: i18next.t('schedules.tabs.reports.statuses.1000'),
  },
};

const TaskDetailTitle = ({ children }) => (<b>{children}</b>);
TaskDetailTitle.propTypes = { children: PropTypes.node.isRequired };

const ScheduleReports = ({ tasks: scheduleTasks, reports }) => {
  const { t } = useTranslation();
  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();
  const [displayReportAtIndex, setDisplayReportAtIndex] = useState(-1);
  const [displayTaskAtIndex, setDisplayTaskAtIndex] = useState(-1);

  const selectedReport = _.get(reports, [displayReportAtIndex], []);
  const theTask = _.get(selectedReport, ['tasks', displayTaskAtIndex], []);

  // on selectionne le premier traitement s'il existe
  useEffect(() => {
    if (!_.isEmpty(selectedReport?.tasks)) {
      setDisplayTaskAtIndex(0);
    }
  }, [selectedReport]);

  const listOfReports = useMemo(() => (
    reports.map((report, reportIndex) => {
      const selected = displayReportAtIndex === reportIndex;

      return (
        <CardWithStatus
          key={`${report.scheduleId}_${report.scheduleExtensionId}_${report.start}`}
          onClick={() => setDisplayReportAtIndex(reportIndex)}
          selected={selected}
          title={format(report.start, 'PPPp')}
          message={_.get(STATUSES, [+report.status, 'message'])}
          tag={report.reprise ? t('schedules.tabs.reports.recovery') : ''}
          max={scheduleTasks?.length || 0}
          value={report?.tasks?.length || 0}
          severity={_.get(STATUSES, [+report.status, 'severity'])}
          rawFile={report.rawFile}
        />
      );
    })
  ), [displayReportAtIndex, reports, scheduleTasks, t]);

  if (_.isEmpty(reports)) {
    return (
      <Grid sx={{ px: 1 }} textAlign="center">
        <TaskDetailTitle>{t('schedules.tabs.reports.no_reports')}</TaskDetailTitle>
      </Grid>
    );
  }

  const printListOfFiles = (name, fileList) => (
    !_.isEmpty(fileList) && [
      <TaskDetailTitle>
        {name}
      </TaskDetailTitle>,
      <List dense>
        {fileList.map((file) => <ListItem>{file}</ListItem>)}
      </List>,
    ]
  );

  const printStatusDetails = (name, details) => printListOfFiles(name, _.map(details, 'libelle'));

  const printTimes = (start, end) => {
    const duration = (start && end)
      ? formatDistance(parseISO(theTask.start), parseISO(theTask.end), { includeSeconds: true })
      : false;

    return (
      <Fragment>
        { start && (
          <div>
            <TaskDetailTitle>
              {t('schedules.tabs.reports.task_start')}
            </TaskDetailTitle>
            {format(theTask.start, 'pp')}
          </div>
        )}

        {end && (
          <div>
            <TaskDetailTitle>
              {t('schedules.tabs.reports.task_end')}
            </TaskDetailTitle>
            {format(theTask.end, 'pp')}
          </div>
        )}

        { duration && (
          <div>
            <TaskDetailTitle>{t('schedules.tabs.reports.task_duration')}</TaskDetailTitle>
            {duration}
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ mt: 1, px: 1, overflow: 'hidden' }}
    >
      <Grid item pt={0} xs={3} display="flex" flexDirection="column" sx={{ maxHeight: '100%' }}>
        <Typography variant="h6">{t('schedules.tabs.reports.columns.reports')}</Typography>
        <Box sx={{ overflowY: 'auto', maxHeight: '100%' }}>
          {listOfReports}
        </Box>
      </Grid>

      <Grid item pt={0} xs={4} display="flex" flexDirection="column" sx={{ maxHeight: '100%' }}>
        <Typography variant="h6">{t('schedules.tabs.reports.columns.tasks')}</Typography>
        <Box sx={{ overflowY: 'auto', maxHeight: '100%' }}>
          <Box flexGrow={1} overflow="auto" mt={1}>
            {
              (_.isEmpty(selectedReport))
                ? t('schedules.tabs.reports.select_report')
                : (
                  selectedReport.tasks.map((task, taskIndex) => (
                    <CardWithStatus
                      key={`${task.scheduleId}_${task.scheduleExtensionId}_${task.start}_${task.rawFile}`}
                      onClick={() => setDisplayTaskAtIndex(taskIndex)}
                      selected={displayTaskAtIndex === taskIndex}
                      title={task.name}
                      rawFile={`${API_ENTRY_POINT}/file?type=8&file=${theTask.rawFile}&logon=${logon}&key=${key}`}
                      severity={_.get(STATUSES, [+task.status, 'severity'])}
                    />
                  ))
                )
            }
          </Box>
        </Box>
      </Grid>

      <Grid item pt={0} xs={5} display="flex" flexDirection="column" sx={{ maxHeight: '100%' }}>
        <Typography variant="h6">{t('schedules.tabs.reports.columns.details')}</Typography>
        <Box sx={{ overflowY: 'auto', maxHeight: '100%' }}>
          <Box flexGrow={1} overflow="auto" mt={1}>
            {
              (_.isEmpty(theTask))
                ? t('schedules.tabs.reports.select_task')
                : (
                  <Paper sx={{ p: 1 }}>
                    {printTimes(theTask.start, theTask.end)}

                    {printStatusDetails('infos', theTask?.infos)}
                    {printStatusDetails('errors', theTask?.errors)}
                    {printStatusDetails('warnings', theTask?.warnings)}
                    {printStatusDetails('duplicates', theTask?.duplicates)}

                    {printListOfFiles('filesToProcess', theTask?.filesToProcess)}
                    {printListOfFiles('filesProcessed', theTask?.filesProcessed)}
                    {printListOfFiles('filesProcessedKo', theTask?.filesProcessedKo)}
                    {printListOfFiles('filesIgnored', theTask?.filesIgnored)}
                  </Paper>
                )
            }
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

ScheduleReports.propTypes = {
  tasks: PropTypes.arrayOf(scheduleTraitementPropType.isRequired),
  reports: PropTypes.arrayOf(scheduleRapportPropType.isRequired),
};

ScheduleReports.defaultProps = {
  tasks: [],
  reports: [],
};

export default ScheduleReports;

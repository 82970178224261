export const types = {
  OPEN_DIALOG_ADD_TO_CART: '@ACTIONS/OPEN_DIALOG_ADD_TO_CART',
  CLOSE_DIALOG_ADD_TO_CART: '@ACTIONS/CLOSE_DIALOG_ADD_TO_CART',
  OPEN_DIALOG_CREATE_ALERT: '@ACTIONS/OPEN_DIALOG_CREATE_ALERT',
  CLOSE_DIALOG_CREATE_ALERT: '@ACTIONS/CLOSE_DIALOG_CREATE_ALERT',
  OPEN_DIALOG_ADD_TO_NEWSLETTER: '@ACTIONS/OPEN_DIALOG_ADD_TO_NEWSLETTER',
  CLOSE_DIALOG_ADD_TO_NEWSLETTER: '@ACTIONS/CLOSE_DIALOG_ADD_TO_NEWSLETTER',
  OPEN_DIALOG_SEND_TO_FRIEND: '@ACTIONS/OPEN_DIALOG_SEND_TO_FRIEND',
  CLOSE_DIALOG_SEND_TO_FRIEND: '@ACTIONS/CLOSE_DIALOG_SEND_TO_FRIEND',
  OPEN_DIALOG_EXPORT: '@ACTIONS/OPEN_DIALOG_EXPORT',
  CLOSE_DIALOG_EXPORT: '@ACTIONS/CLOSE_DIALOG_EXPORT',
  OPEN_DIALOG_UPLOAD_FILES_TO_CREATE_DOCUMENTS: '@ACTIONS/OPEN_DIALOG_UPLOAD_FILES_TO_CREATE_DOCUMENTS',
  CLOSE_DIALOG_UPLOAD_FILES_TO_CREATE_DOCUMENTS: '@ACTIONS/CLOSE_DIALOG_UPLOAD_FILES_TO_CREATE_DOCUMENTS',
  OPEN_DIALOG_UPLOAD_FILES: '@ACTIONS/OPEN_DIALOG_UPLOAD_FILES',
  CLOSE_DIALOG_UPLOAD_FILES: '@ACTIONS/CLOSE_DIALOG_UPLOAD_FILES',
  OPEN_SIDENAV_SUMMARIZE: '@ACTIONS/OPEN_SIDENAV_SUMMARIZE',
  CLOSE_SIDENAV_SUMMARIZE: '@ACTIONS/CLOSE_SIDENAV_SUMMARIZE',
};

export const openDialogAddToCart = (params) => ({
  type: types.OPEN_DIALOG_ADD_TO_CART,
  params,
});

export const closeDialogAddToCart = () => ({
  type: types.CLOSE_DIALOG_ADD_TO_CART,
});

export const openDialogCreateAlert = () => ({
  type: types.OPEN_DIALOG_CREATE_ALERT,
});

export const closeDialogCreateAlert = () => ({
  type: types.CLOSE_DIALOG_CREATE_ALERT,
});

export const openDialogAddToNewsletter = (params) => ({
  type: types.OPEN_DIALOG_ADD_TO_NEWSLETTER,
  params,
});

export const closeDialogAddToNewsletter = () => ({
  type: types.CLOSE_DIALOG_ADD_TO_NEWSLETTER,
});

export const openDialogSendToFriend = () => ({
  type: types.OPEN_DIALOG_SEND_TO_FRIEND,
});

export const closeDialogSendToFriend = () => ({
  type: types.CLOSE_DIALOG_SEND_TO_FRIEND,
});

export const openDialogExport = () => ({
  type: types.OPEN_DIALOG_EXPORT,
});

export const closeDialogExport = () => ({
  type: types.CLOSE_DIALOG_EXPORT,
});

export const openDialogUploadFilesToCreateDocuments = () => ({
  type: types.OPEN_DIALOG_UPLOAD_FILES_TO_CREATE_DOCUMENTS,
});

export const closeDialogUploadFilesToCreateDocuments = () => ({
  type: types.CLOSE_DIALOG_UPLOAD_FILES_TO_CREATE_DOCUMENTS,
});

export const openDialogUploadFiles = () => ({
  type: types.OPEN_DIALOG_UPLOAD_FILES,
});

export const closeDialogUploadFiles = () => ({
  type: types.CLOSE_DIALOG_UPLOAD_FILES,
});

export const openSidenavSummarize = () => ({
  type: types.OPEN_SIDENAV_SUMMARIZE,
});

export const closeSidenavSummarize = () => ({
  type: types.CLOSE_SIDENAV_SUMMARIZE,
});

import { types } from 'generic/core/schedules/actions';

const schedulesReducer = (
  state = {
    schedules: [],
    form: {},
    loading: true,
    editSchedule: {},
  },
  action,
) => {
  switch (action.type) {
    case types.CLEANUP_SCHEDULE: {
      return {
        ...state,
        editSchedule: {},
      };
    }
    case types.CLEANUP_SCHEDULES: {
      return {
        ...state,
        schedules: [],
      };
    }
    case types.FETCH_SCHEDULES: {
      return {
        ...state,
        schedules: [],
        loading: true,
      };
    }
    case types.FETCH_SCHEDULES_SUCCESS: {
      return {
        ...state,
        schedules: action.schedules,
        loading: false,
      };
    }
    case types.FETCH_SCHEDULES_ERROR: {
      return {
        ...state,
        schedules: [],
        loading: false,
      };
    }
    case types.FETCH_SCHEDULE: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.FETCH_SCHEDULE_SUCCESS: {
      return {
        ...state,
        editSchedule: action.schedule,
        loading: false,
      };
    }
    case types.FETCH_SCHEDULE_ERROR: {
      return {
        ...state,
        editSchedule: {},
        loading: false,
      };
    }
    case types.SAVE_SCHEDULE: {
      return {
        ...state,
        saveScheduleLoading: true,
      };
    }
    case types.SAVE_SCHEDULE_SUCCESS: {
      return {
        ...state,
        saveALertLoading: false,
      };
    }
    case types.SAVE_SCHEDULE_ERROR: {
      return {
        ...state,
        responseData: action.response,
        saveALertLoading: false,
      };
    }
    case types.UPDATE_SCHEDULE_EXTENSIONS: {
      console.log(action);
      return {
        ...state,
        editSchedule: {
          ...state.editSchedule,
          schedule_extensions: action.extensions,
        },
      };
    }
    default:
      return state;
  }
};

export default schedulesReducer;

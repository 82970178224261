import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCheckbox, clearSelection } from 'generic/core/selection/actions';
import Selection from 'generic/components/ui/Selection';
import { actionsPropType } from 'generic/core/qes/proptypes';
import _ from 'lodash';
import { getActiveSelectionItems } from 'generic/utils/qesUtils';

const SelectionContainer = ({
  actions,
  canAddToCart,
  canAddToNewsletter,
  canEditInGED,
  canExport,
  canDeleteDocument,
  canSendToFriend,
  canSummarize,
  handleOpenAddToCart,
  handleOpenAddToNewsletter,
  handleOpenSendToFriend,
  handleOpenMultipleGed,
  handleOpenSummarize,
}) => {
  const hasQuickResults = useSelector((state) => !_.isEmpty(state.search.quickResults));
  const checkedItems = useSelector((state) => getActiveSelectionItems(state));
  const dispatch = useDispatch();

  const handleToggleCheckbox = (id, title) => {
    dispatch(toggleCheckbox({
      id,
      item: { title },
      quickResultsScope: hasQuickResults,
    }));
  };

  const handleClearSelection = () => {
    dispatch(clearSelection({ quickResultsScope: hasQuickResults }));
  };

  return (
    <Selection
      actions={actions}
      canAddToCart={canAddToCart}
      canAddToNewsletter={canAddToNewsletter}
      canEditInGED={canEditInGED}
      canExport={canExport}
      canDeleteDocument={canDeleteDocument}
      canSendToFriend={canSendToFriend}
      canSummarize={canSummarize}
      checkedItems={checkedItems}
      handleToggleCheckbox={handleToggleCheckbox}
      handleClearSelection={handleClearSelection}
      handleOpenAddToCart={handleOpenAddToCart}
      handleOpenAddToNewsletter={handleOpenAddToNewsletter}
      handleOpenSendToFriend={handleOpenSendToFriend}
      handleOpenMultipleGed={handleOpenMultipleGed}
      handleOpenSummarize={handleOpenSummarize}
    />
  );
};

SelectionContainer.propTypes = {
  actions: PropTypes.arrayOf(actionsPropType),
  canAddToCart: PropTypes.bool.isRequired,
  canAddToNewsletter: PropTypes.bool.isRequired,
  canEditInGED: PropTypes.bool.isRequired,
  canExport: PropTypes.bool.isRequired,
  canDeleteDocument: PropTypes.bool.isRequired,
  canSendToFriend: PropTypes.bool.isRequired,
  canSummarize: PropTypes.bool.isRequired,
  handleOpenAddToCart: PropTypes.func.isRequired,
  handleOpenAddToNewsletter: PropTypes.func.isRequired,
  handleOpenSendToFriend: PropTypes.func.isRequired,
  handleOpenMultipleGed: PropTypes.func.isRequired,
  handleOpenSummarize: PropTypes.func.isRequired,
};

SelectionContainer.defaultProps = {
  actions: [],
};

export default SelectionContainer;

import React, { lazy, useRef } from 'react';
import {
  Switch,
  Redirect,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import 'i18n';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from '@mui/material';
import {
  Clear,
} from '@mui/icons-material';
import { SnackbarUtilsConfigurator } from 'generic/utils/snackbar';
import configureStore, { history } from 'generic/core/store';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';

import AlertContainer from 'generic/containers/AlertContainer';
import AlertsContainer from 'generic/containers/AlertsContainer';
import AnRRoutesWrapper from 'generic/containers/anr/RoutesWrapper';
import CartsContainer from 'generic/containers/CartsContainer';
import CartContainer from 'generic/containers/CartContainer';
import ConfigWrapper from 'generic/containers/ConfigWrapper';
import ConfirmDialogContainer from 'generic/containers/ConfirmDialogContainer';
import LogoutContainer from 'generic/containers/auth/LogoutContainer';
import MailingListsContainer from 'generic/containers/MailingListsContainer';
import NetworkGraphWrapper from 'generic/components/dashboard-items/highchart-extensions/NetworkGraphWrapper';
import NewslettersContainer from 'generic/containers/NewslettersContainer';
import NewsletterContainer from 'generic/containers/NewsletterContainer';
import ProfileContainer from 'generic/containers/ProfileContainer';
import ProtectedRoutes from 'generic/components/routes/ProtectedRoutes';
import PublicRoutes from 'generic/components/routes/PublicRoutes';
import ResultsHOC from 'generic/containers/ResultsHOC';
import SchedulesContainer from 'generic/containers/SchedulesContainer';
import ScheduleContainer from 'generic/containers/ScheduleContainer';
import UsersContainer from 'generic/containers/UsersContainer';
import UsersMailingListContainer from 'generic/containers/UsersMailingListContainer';

import { CONSTANTS } from 'generic/core/constants';
import { overrideRessource } from 'generic/utils/utils';

const AuthenticationLanding = overrideRessource('components/pages/AuthenticationLanding');

const GEDContainerHOC = lazy(() => import('generic/components/pages/GEDContainerHOC'));

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsOfflineExporting(Highcharts);

const store = configureStore();
const { MAIN_PAGE } = CONSTANTS;

const snackbarClearButton = (ref) => (key) => (
  <Button variant="text" onClick={() => { ref.current.closeSnackbar(key); }}>
    <Clear />
  </Button>
);

function App() {
  const notistackRef = useRef();
  const { t } = useTranslation();

  return (
    <Provider store={store}>
      <ConfigWrapper>
        <NetworkGraphWrapper />
        <ConfirmDialogContainer />
        <SnackbarProvider
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          ref={notistackRef}
          action={snackbarClearButton(notistackRef)}
        >
          <SnackbarUtilsConfigurator />
          <ConnectedRouter history={history}>
            <Switch>
              { /* protégé */ }
              <ProtectedRoutes
                path="/search/:resultsPath/:baseId?"
                keepFormConfig
                exact={false}
                title={t('route.results')}
                component={ResultsHOC}
              />
              <ProtectedRoutes
                path="/ged/:baseId?/:id?"
                keepFormConfig
                component={GEDContainerHOC}
                title={t('route.article')}
              />
              <ProtectedRoutes
                path="/anr"
                exact={false}
                component={AnRRoutesWrapper}
                title={t('route.anr')}
              />
              <ProtectedRoutes
                path="/carts"
                component={CartsContainer}
                title={t('route.carts')}
              />
              <ProtectedRoutes
                path="/carts/:id"
                component={CartContainer}
                title={t('route.carts')}
              />
              <ProtectedRoutes
                path="/newsletters"
                component={NewslettersContainer}
                title={t('route.newsletters')}
              />
              <ProtectedRoutes
                path="/newsletters/:id"
                component={NewsletterContainer}
                title={t('route.newsletters')}
              />
              <ProtectedRoutes
                path="/users-mailing-list"
                component={UsersMailingListContainer}
                title={t('route.users_mailing_list')}
              />
              <ProtectedRoutes
                path="/alerts"
                component={AlertsContainer}
                title={t('route.alerts')}
              />
              <ProtectedRoutes
                path="/alerts/:id"
                component={AlertContainer}
                title={t('route.alerts')}
              />
              <ProtectedRoutes
                path="/mailingLists/"
                component={MailingListsContainer}
                title={t('route.mailing_lists')}
              />
              <ProtectedRoutes
                path="/users"
                component={UsersContainer}
                title={t('route.users')}
              />
              <ProtectedRoutes
                path="/profile"
                component={ProfileContainer}
                title={t('route.profile')}
              />
              <ProtectedRoutes
                path="/schedules"
                component={SchedulesContainer}
                title={t('route.schedules')}
              />
              <ProtectedRoutes
                path="/schedules/:id"
                component={ScheduleContainer}
                title={t('route.schedule_show')}
              />
              <ProtectedRoutes
                path="/logout"
                component={LogoutContainer}
              />

              { /* non accessible si loggué */ }
              <PublicRoutes
                exact={false}
                path="/login"
                component={AuthenticationLanding}
                title={t('route.app_name')}
              />

              { /* Default */ }
              <Redirect
                to={MAIN_PAGE}
              />
            </Switch>
          </ConnectedRouter>
        </SnackbarProvider>
      </ConfigWrapper>
    </Provider>
  );
}

export default App;

import { types } from 'generic/core/dashboard/actions';
import _ from 'lodash';

const dashboardReducer = (state = {
  widgets: {},
}, action) => {
  switch (action.type) {
    case types.SET_DASHBOARD_LOADING:
    case types.CLEANUP_DASHBOARD:
    case types.FETCH_WIDGETS: {
      const actionWidgetsIds = _.map(action.widgets, 'i');
      let widgets = action.keepOtherWidgetsData ? _.omit({ ...state.widgets }, actionWidgetsIds) : {};
      if (action.keepAllWidgetsData) {
        widgets = { ...state.widgets };
      }
      return {
        ...state,
        widgets,
      };
    }
    case types.FETCH_WIDGET_SUCCESS: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          [action.widgetId]: action.widget,
        },
      };
    }
    case types.FETCH_WIDGET_ERROR: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          [action.widgetId]: {
            error: action.response,
            series: [],
          },
        },
      };
    }
    case types.SET_CONTEXT_MENU: {
      return {
        ...state,
        contextMenu: {
          ...action.datas,
        },
      };
    }
    case types.UNSET_CONTEXT_MENU: {
      return {
        ...state,
        contextMenu: {},
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
